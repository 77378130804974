import React, { useState } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";
import education from "../../assets/Resume/education.svg";
import workHistory from "../../assets/Resume/work-history.svg";
import programming from "../../assets/Resume/programming-skills.svg";
import projects from "../../assets/Resume/projects.svg";
import interests from "../../assets/Resume/interests.svg";
export default function Resume(props) {
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffSetStyle, setCarousalOffSetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  // const fadeInSubscription = //turn on later
  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span className="bullet-heading" style={{ display: "flex" }}>
            {props.heading ? props.heading : ""}
            {props.redirect ? (
              <a href={props.redirect} target="_blank" rel="noreferrer">
                <svg
                  class="pl-1"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18 20.75H6C5.27065 20.75 4.57118 20.4603 4.05546 19.9445C3.53973 19.4288 3.25 18.7293 3.25 18V6C3.25 5.27065 3.53973 4.57118 4.05546 4.05546C4.57118 3.53973 5.27065 3.25 6 3.25H12C12.1989 3.25 12.3897 3.32902 12.5303 3.46967C12.671 3.61032 12.75 3.80109 12.75 4C12.75 4.19891 12.671 4.38968 12.5303 4.53033C12.3897 4.67098 12.1989 4.75 12 4.75H6C5.66848 4.75 5.35054 4.8817 5.11612 5.11612C4.8817 5.35054 4.75 5.66848 4.75 6V18C4.75 18.3315 4.8817 18.6495 5.11612 18.8839C5.35054 19.1183 5.66848 19.25 6 19.25H18C18.3315 19.25 18.6495 19.1183 18.8839 18.8839C19.1183 18.6495 19.25 18.3315 19.25 18V12C19.25 11.8011 19.329 11.6103 19.4697 11.4697C19.6103 11.329 19.8011 11.25 20 11.25C20.1989 11.25 20.3897 11.329 20.5303 11.4697C20.671 11.6103 20.75 11.8011 20.75 12V18C20.75 18.7293 20.4603 19.4288 19.9445 19.9445C19.4288 20.4603 18.7293 20.75 18 20.75Z" />
                  <path d="M20 8.75C19.8019 8.74741 19.6126 8.66756 19.4725 8.52747C19.3324 8.38737 19.2526 8.19811 19.25 8V4.75H16C15.8011 4.75 15.6103 4.67098 15.4697 4.53033C15.329 4.38968 15.25 4.19891 15.25 4C15.25 3.80109 15.329 3.61032 15.4697 3.46967C15.6103 3.32902 15.8011 3.25 16 3.25H20C20.1981 3.25259 20.3874 3.33244 20.5275 3.47253C20.6676 3.61263 20.7474 3.80189 20.75 4V8C20.7474 8.19811 20.6676 8.38737 20.5275 8.52747C20.3874 8.66756 20.1981 8.74741 20 8.75Z" />
                  <path d="M13.5 11.25C13.3071 11.2352 13.1276 11.1455 13 11C12.877 10.8625 12.809 10.6845 12.809 10.5C12.809 10.3155 12.877 10.1375 13 10L19.5 3.5C19.5687 3.42631 19.6515 3.36721 19.7435 3.32622C19.8355 3.28523 19.9348 3.26319 20.0355 3.26141C20.1362 3.25963 20.2362 3.27816 20.3296 3.31588C20.423 3.3536 20.5078 3.40974 20.579 3.48096C20.6503 3.55218 20.7064 3.63701 20.7441 3.7304C20.7818 3.82379 20.8004 3.92382 20.7986 4.02452C20.7968 4.12523 20.7748 4.22454 20.7338 4.31654C20.6928 4.40854 20.6337 4.49134 20.56 4.56L14 11C13.8724 11.1455 13.6929 11.2352 13.5 11.25Z" />
                </svg>
              </a>
            ) : (
              <></>
            )}
          </span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  const resumeBullets = [
    { label: "Education", logoSrc: education },
    { label: "Work History", logoSrc: workHistory },
    {
      label: "Programming Skills",
      logoSrc: programming,
    },
    { label: "Projects", logoSrc: projects },
    { label: "Interests", logoSrc: interests },
  ];

  const programmingSkillsDetails = [
    { skill: "Typescript", ratingPercentage: 85 },
    { skill: "JavaScript", ratingPercentage: 85 },
    { skill: "Python", ratingPercentage: 85 },
    { skill: "C++", ratingPercentage: 85 },
    { skill: "NextJS", ratingPercentage: 85 },
    { skill: "SQL", ratingPercentage: 85 },
    { skill: "React JS", ratingPercentage: 85 },
    { skill: "Tailwind UI", ratingPercentage: 85 },
    { skill: "Express JS", ratingPercentage: 85 },
    { skill: "Node JS", ratingPercentage: 85 },
    { skill: "Mongo DB", ratingPercentage: 85 },
    { skill: "PostgreSQL", ratingPercentage: 85 },
    { skill: "SASS / SCSS ", ratingPercentage: 70 },
    { skill: "HTML", ratingPercentage: 85 },
    { skill: "CSS", ratingPercentage: 85 },
  ];

  const projectDetails = [
    // {
    //   title: 'Personal Portfolio Website',
    //   duration: { fromDate: 'Sept. 2021', toDate: 'Oct. 2021' },
    //   description:
    //     'A personal Portfolio website to showcase my details and projects',
    //   subHeading: ' Technologies used: React, Node, and RxJS',
    // },
    {
      title: "Ricoma.com",
      redirect: "https://ricoma.com/US",
      duration: { fromDate: "Feb. 2022", toDate: "Present" },
      description: "Ricoma Informational Website",
      // subHeading:
      //   "Technologies used: MERN Stack, React, Redux, Node, MongoDB, Mongoose Express, Socket.IO, and Material UI",
    },
    {
      title: "Ricoma Shop",
      redirect: "https://shop.ricoma.com/",
      duration: { fromDate: "Feb.2022", toDate: "Present" },
      description: "Ricoma E-Commerce Website",
      // subHeading:
      //   "Technologies used: PERN Stack, React, Redux, Express Node.js, PostgreSQL, Material UI, and Bootstrap",
    },
  ];

  const resumeDetails = [
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"Northern Illinois University"}
        subHeading={"Computer Science"}
        fromDate={"Graduation Date"}
        toDate={"2026"}
      />
      <ResumeHeading
        heading={"Waubonsee College"}
        subHeading={"Computer Science | GPA: 4.0"}
        fromDate={"Graduated"}
        toDate={"2024"}
      />
      <ResumeHeading
        heading={"University of Memphis"}
        subHeading={"International Business minor Japanese language"}
        fromDate={"2000"}
        toDate={"2005"}
      />

      <ResumeHeading
        heading={"Fullstack Academy of Code"}
        subHeading={"Fullstack Software Engineering and Web Development"}
        fromDate={"2021"}
        toDate={" 2021"}
      />
    </div>,
    //work experience
    <div className="resume-screen-container" key="work-experience">
      <ResumeHeading
        heading={"Reality AI Lab"}
        subHeading={"Software Engineer"}
        fromDate={"2024"}
        toDate={"Present"}
      />
      <div className="experience-description">
        <span className="resume-description-text">
          JavaScript | React | Python | Docker | Google Cloud | Node.js |
          Firebase
        </span>
        <br />
        <div className="experience-description">
          {/* */}
          <br />
        </div>
      </div>
      <br />
      <ResumeHeading
        heading={"Ricoma International"}
        subHeading={"Developer"}
        fromDate={"2022"}
        toDate={"Present"}
      />
      <div className="experience-description">
        <span className="resume-description-text">
          JavaScript | mySQL | PHP | Typescript | Vite | Vue | Microservices
        </span>
        <br />
        <div className="experience-description">
          {/* */}
          <br />
        </div>
      </div>
      <br />
      <ResumeHeading
        heading={"Freelance"}
        subHeading={"Fullstack Software Engineer"}
        fromDate={"2021"}
        toDate={"Present"}
      />
      <div className="experience-description">
        <span className="resume-description-text">
          JavaScript | React | SQL | mySQL| NextJS | React | Mobile Application
          Development
        </span>
        <br />
        <div className="experience-description">
          {/*  */}
          <br />
        </div>
      </div>
      <br />
      <ResumeHeading
        heading={"K.T. Billing"}
        subHeading={"Co-Founder & IT Developer"}
        fromDate={"June 2016"}
        toDate={"2022"}
      />
      <div className="experience-description">{/*  */}</div>
      {/*  */}
    </div>,
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          {/*  */}
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>,
    <div className="resume-screen-container" key="projects">
      {projectDetails.map((projectDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectDetails.title}
          redirect={projectDetails.redirect}
          subHeading={projectDetails.subHeading}
          description={projectDetails.description}
          fromDate={projectDetails.duration.fromDate}
          toDate={projectDetails.duration.toDate}
        />
      ))}
    </div>,
    <div className="resume-screen-container" key="interests">
      <ResumeHeading
        heading={"Martial Artist"}
        description={"Taekwondo Instructor Intern and Hapkido Martial Arts"}
      />
      <ResumeHeading
        heading={"Cooking"}
        description={
          "I'm very big on grilling especially smoking ribs Memphis style"
        }
      />
    </div>,
  ];

  const handleCarousel = (index) => {
    let offsetHeight = 360;
    let newCarouselOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };
    setCarousalOffSetStyle(newCarouselOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousel(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img className="bullet-logo" src={`${bullet.logoSrc}`} alt="B" />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreen = () => {
    return (
      <div
        style={carousalOffSetStyle.style}
        className="resume-details-carousel"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={"My Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullets-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreen()}</div>
        </div>
      </div>
    </div>
  );
}
