import React from "react";
// import { useEffect } from 'react';
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./AboutMe.css";
export default function AboutMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  // const fadeInSubscription = //turn on later
  ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const SCREEN_CONSTANTS = {
    description:
      "Software Engineer in Chicago area. Passionate about driving positive change through product development. Comfortable working independently or as part of a team. Pursuing a computer science degree. Strong professional with Software Engineering Certification. Ready to contribute to organizations.",
    highlights: {
      bullets: [
        "Full stack web and mobile development",
        "Interactive Front End as per the design",
        "React, Vue, NextJS, Python, C++",
        "State Management",
        "Building REST API",
        "Managing databases",
      ],
      heading: "Here are a Few Highlights:",
    },
  };
  const renderHighlight = () => {
    return SCREEN_CONSTANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };
  return (
    <div
      className="about-me-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="about-me-parent">
        <ScreenHeading title={"About Me"} subHeading={"Why Choose Me?"} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <span className="about-me-description">
              {SCREEN_CONSTANTS.description}
            </span>
            <div className="about-me-highlights">
              <div className="about-me-heading">
                <span>{SCREEN_CONSTANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            <div className="about-me-options">
              {/* <button
                className="btn primary-btn"
                // onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
              > */}
              <a
                className="btn primary-btn"
                href="mailto:cbolosan@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                Email Me
              </a>{" "}
              {/* </button> */}
              {/* <a
                href="Christopher Bolosan Resume.pdf"
                download="Christopher Bolosan Resume.pdf"
              >
                <button className="btn highlighted-btn">Get Resume</button>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
